import { convert } from 'hangul-romanization';

export function getCustomerName(displayName, email) {
  const atIndex = email.indexOf('@');
  if (atIndex === -1) {
    return email;
  }
  return `${displayName}-${email.substring(0, atIndex)}`;
}

function sanitizeAndFixInput(input) {
  // 입력값에서 영문 대소문자, 숫자, 특수문자(-, _)만 남김
  let sanitizedInput = input.replace(/[^A-Za-z0-9-_]/g, '');
  // 길이가 6자 미만인 경우, 부족한 부분을 '_'로 채움
  if (sanitizedInput.length < 6) {
    sanitizedInput = sanitizedInput.padEnd(6, '_');
  }
  // 길이가 64자를 초과하는 경우, 64자까지만 잘라서 반환
  if (sanitizedInput.length > 64) {
    sanitizedInput = sanitizedInput.substring(0, 64);
  }
  return sanitizedInput;
}

export const generateUniqueOrderId = (displayName) => {
  // displayName이 한글이면 영어로 변환
  const sanitizedDisplayName = /[가-힣]/.test(displayName) ? convert(displayName) : displayName;
  // 변환된 이름을 sanitizeAndFixInput 함수에 전달
  const sanitizedInput = sanitizeAndFixInput(sanitizedDisplayName);
  const timestamp = Date.now();
  return `${sanitizedInput}_${timestamp}`;
};

export const checkErrorCode = (errorMessage) => {
  const errorCodeMatch = errorMessage.match(/\[S\d{3}\]/); // [Sxxx] 형태의 코드 추출
  if (errorCodeMatch) {
    const errorCode = errorCodeMatch[0]; // 매칭된 에러 코드
    return errorCode === '[S021]' || errorCode === '[S008]'; // [S021] 또는 [S008]과 비교
  }
  return false;
};
