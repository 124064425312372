/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import CardImg from '../assets/images/card-img.jpg';
import CardImg2 from '../assets/images/card-img-2.jpg';

const Home = () => {
  return (
    <Container>
      <Helmet>
        <title>영정사진 | 장수사진 | Jangsu AI</title>
        <meta
          name='description'
          content='영정사진, 장수사진을 AI로 쉽고 빠르게 합성하는 서비스입니다.'
        />
        <meta
          name='keywords'
          content='영정사진, 장수사진, 영정사진 AI, 장수사진 AI, 영정사진 합성, 장례식 사진'
        />
      </Helmet>
      <Content>
        <StyledLink to='/detail'>
          <Card>
            <CrossImage src={CardImg} alt='portrait_img' />
            <CrossImage src={CardImg2} alt='portrait_img' />
            <TextOverlay>
              <CardTitle>영정사진 | 장수사진</CardTitle>
              <CardSubTitle>영정사진 | 장수사진</CardSubTitle>
              <CardSubTitleH3>영정사진 | 장수사진</CardSubTitleH3>
              <CardDescription>어르신의 아름다운 모습을 정성스레 담아드립니다.</CardDescription>
            </TextOverlay>
          </Card>
        </StyledLink>
      </Content>
    </Container>
  );
};

export default Home;

// 스타일 코드
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 84vh; /* 전체 화면 높이 */
  background-color: #f8f9fa;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Card = styled.div`
  width: 90vw; /* 화면의 80% 너비 */
  height: calc(80vw * 4 / 3); /* 3:4 비율 유지 */
  max-width: 90vw; /* 최대 너비 제한 */
  max-height: calc(80vw * 4 / 3); /* 최대 높이 제한 */
  margin: 16px 0;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CrossImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  opacity: 0;
  animation: fadeInOut 8s infinite;

  &:nth-of-type(1) {
    animation-delay: 0s;
  }

  &:nth-of-type(2) {
    animation-delay: 4s;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

const TextOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  box-sizing: border-box;
`;

const CardTitle = styled.h1`
  font-size: 25px;
  font-weight: bold;
  margin: 0 0 8px 0;
  color: white;
`;

const CardSubTitle = styled.h2`
  display: none;
`;

const CardSubTitleH3 = styled.h3`
  display: none;
`;

const CardDescription = styled.p`
  font-size: 15px;
  margin: 0;
  color: white;
`;
