/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { storage, uploadUserPhoto } from '../firebase/firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Popup from '../components/Popup';

const SubmitPhoto = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true);
  const [isFileInputDisabled, setIsFileInputDisabled] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (!user && !loading) {
      // 로그인되어 있지 않으면 로그인 페이지로 이동
      navigate('/login');
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    if (files.length < 3 || files.length > 5) {
      setIsUploadButtonDisabled(true);
    } else {
      setIsUploadButtonDisabled(false);
    }

    if (files.length >= 5) {
      setIsFileInputDisabled(true);
    } else {
      setIsFileInputDisabled(false);
    }

    return () => {
      filePreviews.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [files, filePreviews]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newFiles = [...files, ...selectedFiles];

    if (newFiles.length > 5) {
      alert('사진은 최대 5장까지 선택 가능합니다.');
      return;
    }

    const newPreviews = selectedFiles.map((file) => URL.createObjectURL(file));
    setFiles(newFiles);
    setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  const handleUpload = async () => {
    if (files.length < 3 || files.length > 5) {
      alert('사진은 최소 3장 이상, 5장 이하여야 합니다.');
      return;
    }

    setUploading(true);
    try {
      for (let file of files) {
        const inputStorageRef = ref(storage, `photos/${user.email}/input/${file.name}`);
        await uploadBytes(inputStorageRef, file);
        const inputDownloadURL = await getDownloadURL(inputStorageRef);
        await uploadUserPhoto(inputDownloadURL, user.uid);
      }

      const placeholderRef = ref(storage, `photos/${user.email}/result/placeholder.txt`);
      const placeholderBlob = new Blob([''], { type: 'text/plain' });
      await uploadBytes(placeholderRef, placeholderBlob);

      setSuccessMessage('사진이 성공적으로 업로드되었습니다.');
      setFiles([]);
      setFilePreviews([]);
      setShowPopup(true);
    } catch (error) {
      console.error('사진 업로드 실패:', error);
      alert('사진 업로드 중 오류가 발생했습니다. 다시 시도해 주세요.');
    }
    setUploading(false);
  };

  const handleRemoveFile = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    const newPreviews = filePreviews.filter((_, i) => i !== index);

    setFiles(newFiles);
    setFilePreviews(newPreviews);
  };

  const handlePopupCancel = () => {
    setShowPopup(false);
    navigate('/');
  };

  if (loading) {
    return <div>로딩 중...</div>; // 로딩 중일 때 UI 처리
  }

  return (
    <Container>
      <Title>{`< 사진 제출 >`}</Title>
      <UploadSection>
        <FileInputLabel htmlFor='photo' disabled={isFileInputDisabled}>
          제출 사진 선택 (3장 ~ 5장)
        </FileInputLabel>
        <FileInput
          type='file'
          id='photo'
          accept='image/*'
          multiple
          onChange={handleFileChange}
          disabled={isFileInputDisabled}
        />
        <PreviewSection>
          {filePreviews.map((preview, index) => (
            <PreviewWrapper key={files[index]?.name || index}>
              <PreviewImage src={preview} alt={`preview-${index}`} />
              <RemoveButton onClick={() => handleRemoveFile(index)}>x</RemoveButton>
            </PreviewWrapper>
          ))}
        </PreviewSection>
        <UploadButton onClick={handleUpload} disabled={uploading || isUploadButtonDisabled}>
          {uploading ? '업로드 중...' : '업로드'}
        </UploadButton>
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      </UploadSection>
      {uploading ? (
        <Popup show={!showPopup} title='업로드 중...' anotherMessage='대기' />
      ) : (
        <Popup
          show={showPopup}
          onClose={handlePopupCancel}
          title='✅ 업로드 완료'
          message='완성된 결과물은 이메일로 보내드려요 💌'
          secondMessage='(최대 작업시간 : 24시간)'
          anotherMessage='홈으로 이동'
        />
      )}
    </Container>
  );
};

export default SubmitPhoto;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  min-height: 90vh;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const UploadSection = styled.div`
  width: 100%;
  max-width: 500px;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: ${(props) => (props.disabled ? '#ccc' : '#8d8c8c')};
  color: white;
  border-radius: 10px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-bottom: 20px;
  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : '#5c5c5c')};
  }
`;

const FileInput = styled.input`
  display: none;
`;

const PreviewSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

const PreviewWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: #ff4d4d;
  color: white;
  margin: 0;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
  display: flex; /* Flexbox 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 가로 중앙 정렬 */
  &:hover {
    opacity: 0.8;
  }
`;

const UploadButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  &:hover,
  &:active {
    opacity: 0.8;
  }
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled.p`
  color: green;
  margin-top: 20px;
  font-size: 16px;
`;
