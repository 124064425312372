// src/pages/FAQ.jsx
/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import BackIcon from '../assets/images/back-icon.png';
import { ContactButton } from '../components/Button';

const FAQ = () => {
  return (
    <Container>
      <Header>
        <StyledLink to='/settings'>
          <IconImage src={BackIcon} alt='back_icon' />
        </StyledLink>
      </Header>
      <Content>
        <Title>자주 묻는 질문</Title>
        <Question>구매내역 확인 및 환불 요청하기</Question>
        <Question>사진 업로드 & 다운로드가 안돼요</Question>
      </Content>
      <Footer>
        <ContactLink href='mailto:fermacs.seongjoon@gmail.com?subject=문의하기'>
          <ContactButton style={{ width: '100%' }}>문의하기</ContactButton>
        </ContactLink>
      </Footer>
    </Container>
  );
};

export default FAQ;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-bottom: 0.5px solid lightgray;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const IconImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 30px 10px;
`;

const Content = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Question = styled.div`
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #f5f5f5;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Footer = styled.footer`
  position: fixed;
  bottom: 0.1vh;
  left: 0;
  width: 100%;
  height: 74px;
  padding: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContactLink = styled.a`
  width: 95%;
  text-decoration: none; /* 링크 스타일 제거 */
`;

// const ContactButton = styled.button`
//   width: 100%;
//   padding: 15px;
//   font-size: 16px;
//   background-color: #000;
//   color: white;
//   border: none;
//   border-radius: 15px;
//   cursor: pointer;
//   &:hover,
//   &:active {
//     opacity: 0.8;
//   }
// `;
